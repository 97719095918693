import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Products = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Products");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        sortable: false,
        placeholder: "Search",
        show: false,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "store_name",
        type: "text",
        placeholder: "Store_store_name",
        label: "Store_store_name",
        header: "Store Name",
        width: 110,
        parentField: "store_name",
        id: "Store_store_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "left",
        subFieldName: "store_name",
        show: true,
        field: "store_name",
        mobile: true,
        sortable: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
        capitalizeTableText: true,
      },
      {
        name: "product_name",
        type: "text",
        placeholder: "Product Name",
        label: "Product Name",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Product Name",
        derivedValue: "product_name=undefined",
        sortable: true,
        filter: true,
        id: "product_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "product_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        capitalizeTableText: true,
      },
      {
        name: "sku",
        type: "Text",
        placeholder: "Sku",
        label: "Sku",
        width: 120,
        header: "Sku",
        id: "sku",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "sku",
        sortable: true,
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "msrp",
        type: "Text",
        placeholder: "Msrp",
        label: "Msrp",
        width: 120,
        header: "Msrp",
        id: "msrp",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "msrp",
        sortable: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "price",
        type: "Text",
        placeholder: "price",
        label: "price",
        width: 120,
        header: "Price",
        id: "price",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        sortable: true,
        filter: true,
        field: "price",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "stock",
        type: "Text",
        placeholder: "stock",
        label: "stock",
        width: 120,
        header: "Stock",
        id: "stock",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        sortable: true,
        filter: true,
        field: "stock",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "school_name",
        type: "Text",
        placeholder: "stock",
        label: "stock",
        width: 120,
        header: "School Name",
        derivedValue: "school_name=school_name=undefined",
        capitalizeTableText: true,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "school_name",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "School",
        searchField: "Name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["Name"],
        controllerName: "School",
        searchApi: "schools",
        isMultiple: false,
        textAlign: "left",
        show: true,
        filter: true,
        sortable: true,
        field: "school_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "other_name",
        type: "text",
        placeholder: "Other Name",
        label: "Other Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Other Name",
        derivedValue: "other_name=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "other_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "other_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "display_order",
        type: "text",
        placeholder: "Color",
        label: "Display Order",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Display Order",
        derivedValue: "color=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "display_order",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "display_order",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cost",
        type: "text",
        placeholder: "cost",
        label: "cost",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Cost",
        derivedValue: "cost=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cost",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "cost",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        capitalizeTableText: true,
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "purchase_limit",
        type: "text",
        placeholder: "Purchase Limit",
        label: "Purchase Limit",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Purchase Limit",
        derivedValue: "purchase_limit=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchase_limit",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "purchase_limit",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "frequency",
        type: "text",
        placeholder: "Frequency",
        label: "Frequency",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Frequency",
        derivedValue: "frequency=frequency=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "frequency",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "frequency",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availableFrom",
        type: "date",
        placeholder: "Available From",
        label: "Available From",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "Start Date",
        derivedValue: "availableFrom=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "availableFrom",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "MMM DD YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "availableFrom",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availableTo",
        type: "date",
        placeholder: "Available To",
        label: "Available To",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "End Date",
        derivedValue: "availableTo=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "availableTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "MMM DD YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "availableTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "isDuplicateProduct",
        type: "text",
        placeholder: "Available To",
        label: "Available To",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "is Duplicate Product ",
        derivedValue: "availableTo=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "isDuplicateProduct",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "isDuplicateProduct",
        capitalizeTableText: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "school_name",
        type: "relateAutoComplete",
        placeholder: "School Name",
        label: "School Name",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "school_name=school_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "school_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "School",
        searchField: "Name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["Name"],
        controllerName: "School",
        searchApi: "schools",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "store_name",
        type: "relateAutoComplete",
        placeholder: "Select Stores",
        label: "Select Stores",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "store_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "store_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1031,
        searchField: "store_name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["store_name"],
        fieldForKey: "school_id",
        hasDependencyField: 'school_name',
        controllerName: "Store",
        searchApi: "stores",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "product_name",
        type: "text",
        placeholder: "Product Name",
        label: "Product Name",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "product_name=undefined",
        sortable: true,
        filter: true,
        id: "product_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "other_name",
        type: "text",
        placeholder: "Other Name",
        label: "Other Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "other_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "other_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "color",
        type: "dropDown",
        placeholder: "Color",
        label: "Color",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "color=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "color",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        options: config.colorTypes,
        isMultiSelect: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "size",
        type: "dropDown",
        placeholder: "Size",
        label: "Size",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "size=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "size",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        options: config.sizeTypes,
        isMultiSelect: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "cost",
        type: "text",
        placeholder: "Cost",
        label: "Cost",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "cost=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cost",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "cost",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "price",
        type: "text",
        placeholder: "Price",
        label: "Price",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "price=price=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "msrp",
        type: "text",
        placeholder: "Msrp",
        label: "Msrp",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "msrp=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "msrp",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },

      {
        name: "stock",
        type: "text",
        placeholder: "Stock",
        label: "Stock",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "stock=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "stock",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "purchase_limit",
        type: "text",
        placeholder: "Purchase Limit",
        label: "Purchase Limit",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "purchase_limit=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchase_limit",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "frequency",
        type: "text",
        placeholder: "Frequency",
        label: "Frequency",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue: "frequency=frequency=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "frequency",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "display_order",
        type: "number",
        placeholder: "Display Order",
        label: "Display Order",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue: "display_order=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "display_order",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "availableFrom",
        type: "date",
        placeholder: "Available From",
        label: "Available From",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        derivedValue: "availableFrom=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "availableFrom",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "MMM DD YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "availableTo",
        type: "date",
        placeholder: "Available To",
        label: "Available To",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        derivedValue: "availableTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "availableTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "MMM DD YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        "value": "",
        "type": "multipleprofile",
        "name": "multipleImages",
        "label": "Choose Image",
        "id": "Image",
        "placeholder": "Image",
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: true,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "product_name",
        type: "text",
        placeholder: "Product Name",
        label: "Product Name",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Product Name",
        derivedValue: "product_name=undefined",
        sortable: true,
        filter: true,
        id: "product_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "product_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "school_name",
        type: "relateAutoComplete",
        placeholder: "School Name",
        label: "School Name",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "School Name",
        derivedValue: "school_name=school_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "school_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "School",
        searchField: "Name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["Name"],
        controllerName: "School",
        searchApi: "schools",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "school_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "School_Name",
        type: "text",
        placeholder: "School_Name",
        label: "School_Name",
        header: "School Name",
        width: 110,
        parentField: "school_name",
        id: "School_Name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "Name",
        show: true,
        field: "School_Name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "store_name",
        type: "relateAutoComplete",
        placeholder: "Select Stores",
        label: "Select Stores",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Select Stores",
        derivedValue: "store_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "store_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1031,
        searchField: "store_name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["store_name"],
        controllerName: "Store",
        searchApi: "stores",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "store_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Store_store_name",
        type: "text",
        placeholder: "Store_store_name",
        label: "Store_store_name",
        header: "Store store_name",
        width: 110,
        parentField: "store_name",
        id: "Store_store_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "store_name",
        show: true,
        field: "Store_store_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "other_name",
        type: "text",
        placeholder: "Other Name",
        label: "Other Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Other Name",
        derivedValue: "other_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "other_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "other_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "color",
        type: "dropDown",
        placeholder: "Color",
        label: "Color",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Color",
        derivedValue: "color=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "color",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "color",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "size",
        type: "dropDown",
        placeholder: "Size",
        label: "Size",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Size",
        derivedValue: "size=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "size",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "size",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cost",
        type: "text",
        placeholder: "Cost",
        label: "Cost",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Cost",
        derivedValue: "cost=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cost",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "cost",
        showOrHideFields: [],
        fieldName: "cost",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "price",
        type: "text",
        placeholder: "Price",
        label: "Price",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Price",
        derivedValue: "price=price=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "price",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "msrp",
        type: "text",
        placeholder: "Msrp",
        label: "Msrp",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Msrp",
        derivedValue: "msrp=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "msrp",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "msrp",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "stock",
        type: "text",
        placeholder: "Stock",
        label: "Stock",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Stock",
        derivedValue: "stock=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "stock",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "stock",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "purchase_limit",
        type: "text",
        placeholder: "Purchase Limit",
        label: "Purchase Limit",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Purchase Limit",
        derivedValue: "purchase_limit=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchase_limit",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "purchase_limit",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "frequency",
        type: "text",
        placeholder: "Frequency",
        label: "Frequency",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Frequency",
        derivedValue: "frequency=frequency=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "frequency",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "frequency",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "display_order",
        type: "number",
        placeholder: "Display Order",
        label: "Display Order",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Display Order",
        derivedValue: "display_order=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "display_order",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "display_order",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availableFrom",
        type: "date",
        placeholder: "Available From",
        label: "Available From",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "Available From",
        derivedValue: "availableFrom=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "availableFrom",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss ",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "availableFrom",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availableTo",
        type: "date",
        placeholder: "Available To",
        label: "Available To",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "Available To",
        derivedValue: "availableTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "availableTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss ",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "availableTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: true,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };


  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();


  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }

    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.products}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {

          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }

        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={false}
          PrintRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }

          printRequried={false}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },


          ]}

          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.products}
          globalSearch={
            "Product Name / School Name"
          }
          displayName="Products"
          type="Products"
          routeTo={apiCalls.products}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.products?.toLowerCase()}
          apiUrl={apiCalls.products}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="products"
          apiUrl={apiCalls.products}
        />
      ) : null}
    </span>
  );
};

export default Products;
