import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const OrdersSummaryByClasses = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState()

  const params = useParams();
  useEffect(() => {
    getStoreOptions()
    let screenPermissions = RolePermissions.screenPermissions(
      "Orders Summary By Class"
    );
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);


  const getStoreOptions = () => {
    fetchMethodRequest('GET', 'stores').then(async (response) => {
      if (response) {
        let states = response['stores'];
        let roleOptions = [];
        if (states && states.length > 0) {
          for (let i = 0; i < states.length; i++) {
            roleOptions.push({ label: states[i]['store_name'], value: states[i]['_id'] })
          }
          setSchoolOptions(roleOptions)
        }
      }
    }).catch((err) => {
      return err
    })
  }

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "Sno",
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 200,
        field: "product_name",
        mobile: true,
        header: "product",
        filter: true,
        sortable: true,
        capitalizeTableText:true,
        displayInSettings: true,
      }, {
        show: true,
        textAlign: "left",
        width: 200,
        field: "class_nameSearch",
        mobile: true,
        header: "Class Name",
        filter: true,
        sortable: true,

        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 200,
        field: "child_name",
        fieldType: "Array",
        mobile: true,
        header: "Child Name",
        filter: true,
        sortable: true,

        displayInSettings: true,
      }, {
        show: true,
        textAlign: "center",
        width: 200,
        field: "c_purchase_qty",
        fieldType: "Array",
        mobile: true,
        header: "Purchase Qty",
        filter: true,
        sortable: true,

        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 100,
        field: "comments",
        header: "Comments",
        filter: true,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 100,
        field: "store_name",
        header: "Store Name",
        sortable: true,
        displayInSettings: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        globalSearchField: "true",
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "product",
        type: "text",
        placeholder: "Product",
        label: "Product",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "product=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "product",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "className",
        type: "text",
        placeholder: "Class Name",
        label: "Class Name",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "className=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "className",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "childName",
        type: "text",
        placeholder: "Child Name",
        label: "Child Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "childName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "childName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "PurchaseQty",
        type: "text",
        placeholder: "Purchase Qty",
        label: "Purchase Qty",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "PurchaseQty=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PurchaseQty",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "comments",
        type: "text",
        placeholder: "Comments",
        label: "Comments",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);

  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: true,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "product",
        type: "text",
        placeholder: "Product",
        label: "Product",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Product",
        derivedValue: "product=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "product",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "product",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "className",
        type: "text",
        placeholder: "Class Name",
        label: "Class Name",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Class Name",
        derivedValue: "className=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "className",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "className",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "childName",
        type: "text",
        placeholder: "Child Name",
        label: "Child Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Child Name",
        derivedValue: "childName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "childName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "childName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "PurchaseQty",
        type: "text",
        placeholder: "Purchase Qty",
        label: "Purchase Qty",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Purchase Qty",
        derivedValue: "PurchaseQty=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "PurchaseQty",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "PurchaseQty",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "comments",
        type: "text",
        placeholder: "Comments",
        label: "Comments",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Comments",
        derivedValue: "comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };


  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }

    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.ordersSummaryByClasses}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {

          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }

        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={false}
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={true}
          PrintRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }

          printRequried={false}
          actionsTypes={false}

          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.ordersSummaryByClasses}
          globalSearch={"Store / Product"}
          displayName="Orders Summary By Class"
          type="OrdersSummaryByClasses"
          routeTo={apiCalls.ordersSummaryByClasses}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey='ordersSummaryByClasses'
          apiUrl={apiCalls.ordersSummaryByClasses}
          selectedId={params.id}
          schoolOptions={schoolOptions}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="ordersSummaryByClasses"
          apiUrl={apiCalls.ordersSummaryByClasses}
        />
      ) : null}
    </span>
  );
};

export default OrdersSummaryByClasses;
