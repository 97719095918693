import React, { useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoSelectField = (props) => {
  const id = props.id ? props.id : props.name;
  const label = props.label ? props.label : props.name;
  const optionLabel = props.optionLabel ? props.optionLabel : 'label';
  const name = props.name;
  const options = props.options;
  const placeholder = props.placeholder;
  let field = props.field;

  let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
      />
    );
  };

  useEffect(() => {
    onChange({ value: field.value });
}, [field.value]);

  const onChange = (e) => {
    field.onChange(e.value)
    if (props.onChange) {
      props.onChange(e, props.name); // Pass event and name to onChange
    }
    if (props.type === 'Employees' && props.item) {
      props.handleEmployeeFields(e.value, props.item);
    } 
    if (props?.item?.dependent) {
      props.handleFnEnableControlsBasedOnValue2(e.value, props.item.dependent)
    }
    if (props.screenName == 'Roles' && name == 'roleType') {
      props.handleFnEnableControlsBasedOnValue(e.value)
    }
  }

  return (
    <div className="flex flex-column Do_Select">
      <div className="flex align-center"> {/* Added a wrapper div with flex class */}
        <label htmlFor={id} className="text-capitalize">{label}</label>
        {props.markReq && props.markReq === true && markRequired()}
      </div>
      <Dropdown
        value={field.value}
        optionLabel={optionLabel}
        placeholder={placeholder}
        name={name}
        options={options}
        label={label}
        focusInputRef={field.ref}
        style={props.style}
        // onChange={(e) => field.onChange(e.value)}
        onChange={onChange}
      />
      <small className="text-danger " style={{ marginTop: "5px" }}>{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
    </div>
  )

}

export default DoSelectField;